@import '@/assets/scss/_variables.scss';
.walletLimitsTable {
  .wlt-wrapper:nth-child(2) {
    .wlt-body {
      border-top: none;
    }
  }

  //.item_iconContainer {
  //  .SquareCoinIcon_root {
  //    margin: 0 6px 0 0;
  //  }
  //}

  .wlt-item_iconGateway {
    height: 26px;
    padding: 0 4px;
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    background: rgba(42, 243, 243, 0.06);

    img {
      &:nth-child(2) {
        margin: 0 0 0 6px;
      }
    }
  }

  .wlt-head {
    height: 40px;
    background: #2d4d70;
    box-shadow: 0px 18px 21px rgb(34 39 47 / 14%);
    display: flex;
    position: relative;

    &_wrapper {
      background: #203955;
      display: none;

      .wlt-item {
        width: 100% !important;

        .wlt-item_icon {
          margin: 0 6px 0 0;
        }
      }
    }

    .wlt-item {
      height: 40px;
      display: flex;
      align-items: center;

      &:nth-child(1) {
        width: 100px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.04em;
        color: #fff;
        padding: 0 0 0 10px;

        @media only screen and (min-width: $tablet-width) {
          width: 52%;
          padding: 0 0 0 16px;
          font-size: 18px;
          line-height: 21px;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: calc((100% - 100px) / 3);
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        justify-content: flex-start;

        @media only screen and (min-width: $tablet-width) {
          font-size: 12px;
          line-height: 14px;
          width: 16%;
        }

        @media only screen and (min-width: $large-width) {  
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .wlt-body {
    width: 100%;
    display: flex;
    border-top: solid 1px #2d4d70;

    .wlt-col {
      &:nth-child(1) {
        width: 100px;

        @media only screen and (min-width: $tablet-width) {
          width: 52%;
        }

        .wlt-item {
          height: 100%;
          background: #203955;
          display: flex;
          align-items: center;
          padding: 8px 0 8px 10px;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #fff;

          @media only screen and (min-width: $tablet-width) {
            height: 40px;
            font-size: 16px;
            line-height: 19px;
            padding: 8px 0 8px 16px;
          }

          &_icon {
            margin: 0 6px 0 0;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: calc((100% - 100px) / 3);

        @media only screen and (min-width: $tablet-width) {
          width: 16%;
        }

        .wlt-item {
          background: #203955;
          justify-content: center;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-weight: 400;
          color: #a6aebf;
          font-size: 10px;
          line-height: 12px;
          text-align: left;
          height: 100%;

          @media only screen and (min-width: $tablet-width) {
            height: 40px;
            font-size: 12px;
            line-height: 14px;
          }

          @media only screen and (min-width: $large-width) {  
            font-size: 14px;
            line-height: 16px;
          }

          b {
            font-weight: 400;
            color: #fff;
          }

          &_icon {
            margin: 0 6px 0 0;
          }
        }
      }

      &:nth-child(4) {
        .wlt-item {
          align-items: flex-start;
          padding: 8px 10px 8px 0;
        }
      }
    }
  }
}